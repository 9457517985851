import { useMemo } from "react";
import { METRIC_CONSTANTS } from "../../../../../utils/constantsByLevel";
import { Loader } from "semantic-ui-react";
import useQueryApi from "../../../../../hooks/useQueryApi";

const METRICS_TO_CHECK = [
  '_ACT_HRS',
  '_PROJ_OT_HRS',
  '_VF',
  '_VS',
  '_VF_PCT',
  '_VS_PCT',
  '_VF_WEEKDAY',
  '_VF_WEEKEND',
  '_VF_PCT_WEEKDAY',
  '_VF_PCT_WEEKEND',
  '_VS_WEEKDAY',
  '_VS_WEEKEND',
  '_VS_PCT_WEEKDAY',
  '_VS_PCT_WEEKEND',
  '_FCST_HRS',
  '_SCH_HRS',
  '_ACT_HRS_WEEKDAY',
  '_ACT_HRS_WEEKEND',
  '_FCST_HRS_WEEKDAY',
  '_FCST_HRS_WEEKEND'
];

const CLASSIFICATION_ENUM = {
  Manager: 0,
  Core: 1,
  Operations: 2,
  Specialty: 3,
  'Tool/Pro': 4,
  Supervisor: 5,
  Other: 6,
  Total: 7
};

export const useLaborQueries = ({ fpToggle, timeframe, level }) => {
  const queryParams = { fpToggle, isCore: true };

  const {
    data: laborOverview,
    isError: isErrorOverview,
    isLoading: isLoadingOverview
  } = useQueryApi('queryLaborOverviewByCore', queryParams);

  const {
    data: laborDetailQuery,
    isError: isErrorLaborDetailQuery,
    isLoading: isLoadingLaborDetailQuery
  } = useQueryApi('queryLaborDtlByCore', queryParams);

  const dataLaborOverviewFiltered = useMemo(() => {
    if (isLoadingOverview) return [];
    const filterData = laborOverview.filter(row => row['METRIC_LEVEL'] === METRIC_CONSTANTS[level].METRIC_LEVEL).filter(row => METRICS_TO_CHECK.some(key => row[timeframe + key] !== 0));
    return filterData.sort((left, right) =>
      CLASSIFICATION_ENUM[left['CLASSIFICATION']] > CLASSIFICATION_ENUM[right['CLASSIFICATION']] ? 1 : -1
    );
  }, [laborOverview, timeframe, isLoadingOverview]);

  const laborDetails = useMemo(() => {
    if (isLoadingOverview) return [];

    return laborOverview.filter(row => METRICS_TO_CHECK.some(key => row[timeframe + key] !== 0));
  }, [laborOverview, timeframe, isLoadingOverview]);

  const isError = isErrorOverview || isErrorLaborDetailQuery;
  const isLoading = isLoadingOverview || isLoadingLaborDetailQuery;
  if (isError) return <p>Something went wrong</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  return {
    dataLaborOverview: dataLaborOverviewFiltered,
    laborDetailQuery,
    laborDetails
  };
}
